import $ from "jquery";

var getDateString = function(date, format) {
  var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    getPaddedComp = function(comp) {
      return parseInt(comp) < 10 ? "0" + comp : comp;
    },
    formattedDate = format,
    o = {
      "y+": date.getFullYear(), // year
      "M+": months[date.getMonth()], //month
      "d+": getPaddedComp(date.getDate()), //day
      "h+": getPaddedComp(
        date.getHours() > 12 ? date.getHours() % 12 : date.getHours()
      ), //hour
      "m+": getPaddedComp(date.getMinutes()), //minute
      "s+": getPaddedComp(date.getSeconds()), //second
      "S+": getPaddedComp(date.getMilliseconds()), //millisecond,
      "t+": date.getHours() >= 12 ? "pm" : "am"
    };

  for (var k in o) {
    if (new RegExp("(" + k + ")").test(format)) {
      formattedDate = formattedDate.replace(RegExp.$1, o[k]);
    }
  }
  return formattedDate;
};

if ($("#grid").length) {
  /* ============================= */
  /* ============================= */
  /* ============================= */
  /* ============================= */
  /* ============================= */

  // LOAD MORE BLOG

  let page = 2;

  $("#loadMoreNewsPost").click(function() {
    const button = $(this);

    $.ajax({
      url: "/wp-json/wp/v2/posts?_embed&per_page=10&page=" + page,
      type: "GET",

      beforeSend: function() {
        button.addClass("spinning");
      },

      success: function(data) {
        if (data) {
          $("#grid").append(postsHTML(data));
          if (button.data("max-pages") == page) {
            button.remove();
          } else {
            page++;
          }
        } else {
          button.remove();
        }
        button.removeClass("spinning");
      }
    });
  });

  function postsHTML(posts) {
    let postsHTML = "";

    posts.forEach(function(post) {
      const title = post.title.rendered,
        link = post.link,
        excerpt = post.excerpt.rendered,
        image = post._embedded["wp:featuredmedia"][0].source_url;

      // console.log(post);
      // content = strip(post.content.rendered).substring(0, 229, "...");

      postsHTML += `<div class="item blogPost__item">
      <div class="blogPost__content">
      <div class="blogPost__imgWrap" style="background-image: url(${image});"></div>
      <div class="blogPost__text">
      <small class="date-text">
      ${getDateString(new Date(post.date), "M d, y h:mt")}
      </small>
      <div class="blogPost__title">
      <h2>${title}</h2>
      </div>
      <div class="blogPost__desc">
      ${excerpt}
      </div>
      <a href="${link}" class="blogPost__link"></a>
      </div>
      </div>
      </div>`;
    });

    return postsHTML;
  }
}
