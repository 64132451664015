/** Setting 100vh on the hero doesn't take into account Safari's menu, need to use window.innerHeight instead. */
function setHeroHeight() {
  if (!document.getElementById('hero')) {
    return
  }

  if (window.matchMedia("(min-width: 576px)").matches) {
    return
  }

  document.getElementById('hero').style.minHeight = window.innerHeight + 'px'
}

setHeroHeight()