import Swiper from "swiper";

function swiper($selector, $options) {
  return new Swiper($selector, $options);
}

swiper(".gridSlider__swiper-container", {
  slidersPerView: 1,
  effect: "fade",
  fadeEffect: { crossFade: true },
  loop: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true,
    clickable: true
  }
});

if (jQuery('#partnerImagesCarousel .swiper-slide').length > 1) {
  swiper("#partnerImagesCarousel", {
    slidersPerView: 1,
    effect: "fade",
    fadeEffect: { crossFade: true },
    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    pagination: {
      el: ".swiper-pagination",
      dynamicBullets: true,
      clickable: true
    }
  });
}

swiper(".logo-slider", {
  slidesPerView: 2,
  spaceBetween: 30,
  scrollbar: {
    el: ".swiper-scrollbar",
    draggable: true,
  },
  breakpoints: {
    768: {
      slidesPerView: 3,
      spaceBetween: 30
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 30
    },
    1200: {
      slidesPerView: 7,
      spaceBetween: 20
    }
  }
});

swiper(".brands-slider", {
  speed: 800,
  slidesPerView: 1,
  slidesPerColumn: 1,
  spaceBetween: 30,
  autoplay: {
    delay: 3000
  },
  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true,
    clickable: true
  },

  breakpoints: {
    768: {
      slidesPerView: 2,
      slidesPerColumn: 1
    },
    1024: {
      slidesPerView: 3,
      slidesPerColumn: 2
    },
    1200: {
      slidesPerView: 4,
      slidesPerColumn: 2
    }
  }
});

swiper(".latest-blog__swiper", {
  speed: 800,
  slidesPerView: 1,
  spaceBetween: 30,
  autoplay: {
    delay: 3000
  },
  pagination: {
    el: ".swiper-pagination",
    dynamicBullets: true,
    clickable: true
  },

  breakpoints: {
    768: {
      slidesPerView: 2,
    },
    1200: {
      slidesPerView: 3,
    }
  }
});
