import $ from "jquery";
import "bootstrap";
import "popper.js";

// import "./modules/hero";
import "./modules/navBar";
import "./custom/jqScript";
import "./custom/scripts";
import "./custom/swiper";
import "./custom/blog";
import "./custom/form";